import { useState } from "react";
import { connect } from "react-redux";
import {
  putAction,
  postAction,
  addDownloadHistory,
} from "../../services/actions/AuthAction";
import { TextH2, TextH3, SubTitle } from "../../components/Text";
import { RenderModal, ModalConfirmation } from "../../components/modal";
import { TableFilters } from "../../components/table";
import { LineCircle } from "../../components/progress";
import { BoxFlex } from "../../components/boxes";
import { GlobalButton } from "../../components/buttons";
import { FormDefault } from "../../components/forms";
import { validateForm } from "../../utils/FormFields";
import { validateStatus } from "../../utils/Validate";
import { TypeInput } from "../../utils/Constant";
import { openSnackbar, changeModal } from "../../store/actions";
import { useAuthAxios } from "../../hook";
import Loading from "../../components/animation/Loading";
import EditProcedure from "../../containers/editDataset/EditProcedure";
import EditYearProcedure from "../../containers/editDataset/EditYearProcedure";
import { GenerateRoute } from "../../containers/procedure";
import * as Yup from "yup";
import {
  adjuntarDex,
  readDam,
  readDim,
  readParte,
} from "../../services/actions/ReadFile";
const EditDataset = (props) => {
  const {
    match: { params, url },
    changeModal,
    sesionUser,
  } = props;

  function handleClick(procedure, reload) {
    changeModal(
      true,
      <TableFields
        viewDataSet={url.includes("view-dataset")}
        locationUser={sesionUser.location}
        {...props}
        handleReload={reload}
        procedure={procedure}
      />,
      true,
      "800px"
    );
  }

  function handleEditProcedure(procedure, reload) {
    changeModal(
      true,
      <EditProcedure
        {...props}
        procedure={procedure}
        reload={reload}
        onCancel={() => changeModal(false, null)}
      />,
      false
    );
  }

  function handleEditYear(procedure, reload) {
    changeModal(
      true,
      <EditYearProcedure
        {...props}
        procedure={procedure}
        reload={reload}
        onCancel={() => changeModal(false, null)}
      />,
      false
    );
  }

  function handleGenerateRoute(procedure) {
    changeModal(
      true,
      <GenerateRoute {...props} procedure={procedure} />,
      true,
      "900px"
    );
  }
  const headerTable = [
    {
      key: "internCode",
      name: "Número de interno",
      type: "textAction",
      action: handleGenerateRoute,
    },
    { key: "orderNumber", name: "Número de pedido" },
    { key: "dimNumber", name: "Número de DIM o DEX" },
    {
      key: "validateReference",
      name: "Validar nro de referencia",
      type: "textBoolean",
      translate: {
        true: "Si",
        false: "No",
      },
      color: {
        true: "var(--green)",
        false: "var(--red)",
      },
    },
    { key: "billNumber", name: "Número de factura" },
    { key: "companyName", name: "Compañía", type: "text" },
    { key: "currentStepName", name: "Proceso actual", type: "text" },
  ];

  async function onHandleReportContra() {
    changeModal(
      true,
      <FormReport {...props} url="/Procedure/ReportContravencional" />,
      true,
      "450px"
    );
  }
  async function onHandleReportRevision() {
    changeModal(
      true,
      <FormReport {...props} url="/Procedure/RevicionDeTributos" />,
      true,
      "450px"
    );
  }

  return (
    <>
      <BoxFlex justifyContent="space-between">
        <TextH2 fontSize="2rem" color="var(--blue)">
          Editar trámites
        </TextH2>
        <BoxFlex>
          <GlobalButton
            onClick={onHandleReportContra}
            color="#e2e4f3"
            background="var(--blue)"
            width="auto"
          >
            Campos contravencionales
          </GlobalButton>
          <GlobalButton
            onClick={onHandleReportRevision}
            color="#e2e4f3"
            background="var(--blue)"
            width="auto"
          >
            Revision de tributos
          </GlobalButton>
        </BoxFlex>
      </BoxFlex>
      <TableFilters
        params={`/procedure/openprocedures/${params.id}`}
        functionClick={handleClick}
        header={
          url.includes("view-dataset")
            ? headerTable
            : [
                ...headerTable,
                {
                  key: handleEditProcedure,
                  name: "Acciones",
                  textBtn: "Editar trámite",
                  type: "action",
                },
                {
                  key: handleEditYear,
                  name: "Acciones",
                  textBtn: "Editar Gestion",
                  type: "action",
                },
              ]
        }
        filterBoxes={[
          {
            type: "getSelect",
            label: "Todas las compañías",
            name: "companyId",
            url: "/company/getallcompanies",
            urlName: "id",
            urlLabel: "razonSocial",
          },
        ]}
      />
    </>
  );
};

const FormReport = (props) => {
  const {
    changeModal,
    openSnackbar,
    match: { params },
    url,
  } = props;
  const [load, setload] = useState(false);

  async function onSubmit(value) {
    setload(true);
    const year = String(value.Date.getFullYear());
    const querys = `?Date=-${year.substring(
      year.length - 2,
      year.length
    )}&Type=${params.id}`;
    await postAction(`${url}${querys}`).then((res) => {
      setload(false);
      if (validateStatus(res.status)) {
        changeModal(
          true,
          <>
            <TextH3 fontSize="1.8rem">Enlace del documento:</TextH3>
            <TextH3 textAlign="center">
              <a href={res.data.url} target="_blank" rel="noopener noreferrer">
                Descargar
              </a>
            </TextH3>
          </>,
          true,
          "300px"
        );
        window.open(res.data.url, "_blank");
        openSnackbar("Reporte generado", true, true);
      } else {
        openSnackbar("No se pudo generar el reporte", true, false);
      }
    });
  }

  return (
    <div style={{ overflowY: "hidden" }}>
      <FormDefault
        load={load}
        title="Formulario para generar el reporte"
        textBtn="Generar"
        InputsJson={[
          {
            name: "Date",
            initial: new Date(),
            validate: Yup.string().required("Campo requerido").nullable(),
            control: "datePicker",
            label: "Gestión",
            dateFormat: "yyyy",
            showYearPicker: true,
          },
        ]}
        data={{}}
        onSubmit={onSubmit}
        onCancel={() => changeModal(false, null)}
      />
    </div>
  );
};

const TableFields = (props) => {
  const { procedure, changeModal, openSnackbar, locationUser, viewDataSet } =
    props;
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: `/processstep/GetProcessStepsByProcedureTypeId/${procedure.procedureTypeId}`,
  });

  const [modal, setmodal] = useState({
    state: false,
    field: null,
    reload: null,
  });
  const orderList = [
    { key: "label", name: "Etiqueta" },
    { key: "type", name: "Tipo" },
  ];

  function handleDeleteProcedure(field) {
    changeModal(
      true,
      <ModalConfirmation
        url={`/dataset/deleteDataSet/procedureId/${procedure.id}/fieldId/${field.id}`}
        textBtn="Eliminar"
        textSuccess="Se elimino el valor"
        openSnackbar={openSnackbar}
        onCancel={() =>
          changeModal(true, <TableFields {...props} />, true, "800px")
        }
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres eliminar el valor del campo?
        </TextH2>
        <SubTitle subTitle={field.label}>
          {field.type === "file" ? (
            <a
              onClick={() =>
                addDownloadHistory(
                  field.label,
                  field.initial,
                  procedure.id,
                  "procedure",
                  locationUser
                )
              }
              href={field.initial}
              target="_blank"
              rel="noopener noreferrer"
            >
              Enlace del documento
            </a>
          ) : (
            field.initial
          )}
        </SubTitle>
      </ModalConfirmation>,
      true
    );
  }
  function handleAddHistory(label, initial) {
    addDownloadHistory(
      label,
      initial,
      props.procedure.id,
      "procedure",
      locationUser
    );
  }

  const headerTable = [
    { key: "label", name: "Etiqueta" },
    { key: "initial", name: "Valor", type: "anchor" },
    {
      key: "type",
      name: "Tipo",
      type: "textTranslated",
      translate: TypeInput,
    },
  ];
  return (
    <>
      <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
        Editar datos del procedimiento
      </TextH2>
      <TextH3 color="var(--blue)">Progreso del trámite:</TextH3>
      {loading ? (
        <Loading />
      ) : (
        <LineCircle
          list={response}
          step={procedure.step}
          progress={(procedure.step * 100) / procedure.totalSteps}
        />
      )}
      <br />
      <br />
      <TableFilters
        params={`/field/fieldsbyprocedureId/${props.procedure.id}`}
        functionClick={(field, reload) =>
          viewDataSet
            ? {}
            : setmodal({
                state: !modal.state,
                field: field,
                reload: reload,
              })
        }
        order={orderList}
        header={
          viewDataSet
            ? headerTable
            : [
                ...headerTable,
                {
                  key: handleDeleteProcedure,
                  name: "Acciones",
                  textBtn: "Borrar valor",
                  type: "action",
                },
              ]
        }
        handleAddHistory={handleAddHistory}
      />
      {modal.state && (
        <RenderModal
          width="500px"
          onClose={() =>
            setmodal({
              state: !modal.state,
              field: null,
              reload: null,
            })
          }
        >
          <EditData
            {...props}
            field={modal.field}
            reload={modal.reload}
            onCancel={() =>
              setmodal({
                state: !modal.state,
                field: null,
                reload: null,
              })
            }
          />
        </RenderModal>
      )}
    </>
  );
};

const EditData = (props) => {
  const { field, reload, onCancel, openSnackbar, procedure, handleReload } =
    props;
  const [load, setload] = useState(false);
  const [loadBtn, setloadBtn] = useState(false);

  async function onSubmit(value) {
    setload(true);
    let fileContents = {};
    if (field.type === "file") {
      let formData = new FormData();
      formData.append("Name", field.name);
      formData.append("File", value.value);
      formData.append("Status", "verified");
      formData.append("ProcedureId", procedure.id);
      fileContents = await postAction("/files", formData).then((res) => {
        if (validateStatus(res.status)) {
          return {
            Value: res.data.url,
            ProcedureId: procedure.id,
            FieldId: parseInt(field.id),
            FileId: res.data.id,
          };
        } else {
          openSnackbar("No se pudo subir el archivo", true, false);
        }
      });
    } else {
      fileContents = {
        Value: value.value,
        ProcedureId: procedure.id,
        FieldId: field.id,
      };
    }
    submitDataset(fileContents);
  }

  async function submitDataset(value) {
    setload(true);
    await putAction(
      `/dataset/editdataset/${procedure.id}/fieldid/${field.id}`,
      value
    ).then((res) => {
      setload(false);
      if (validateStatus(res.status)) {
        openSnackbar("Se guardo exitosamente", true, true);
        onCancel();
        reload();
      } else {
        openSnackbar(res.data ? res.data : "No se pudo guardar", true, false);
      }
    });
  }

  function handlePreRender(element) {
    let auxList = [];
    if (element.type === "file") {
      auxList.push({
        ...element,
        control: element.type,
        validate: element.validate ? validateForm[element.type] : null,
        type: "",
        name: "value",
      });
    } else if (element.type === "getButton" || element.type === "selectGet") {
      auxList.push({
        ...element,
        control: "selectGet",
        validate: element.validate ? validateForm[element.type] : null,
        type: "",
        name: "value",
      });
    } else {
      auxList.push({
        ...element,
        type: element.type === "switch" ? "checkbox" : element.type,
        validate: element.validate ? validateForm[element.type] : null,
        name: "value",
      });
    }
    return auxList;
  }
  async function handleRead(name) {
    setloadBtn(true);
    let response = null;
    switch (name) {
      case "documentoDam":
        response = await readDam({ name: name, validate: true }, procedure.id);
        break;
      case "adjuntarDim":
        response = await readDim({ name: name, validate: true }, procedure.id);
        break;
      case "dimRegularizada":
        response = await readDim({ name: name, validate: true }, procedure.id);
        break;
      case "adjuntarDex":
        response = await adjuntarDex(
          { name: name, validate: true },
          procedure.id
        );
        break;
      case "parteDeRecepcion":
        response = await readParte(
          { name: name, validate: true },
          procedure.id
        );
        break;
    }
    setloadBtn(false);
    if (validateStatus(response.readStatus)) {
      openSnackbar("Se guardaron los datos exitosamente", true, true);
      handleReload();
      reload();
      onCancel();
    } else {
      openSnackbar(
        response.data ? response.data : "No se pudo guardar",
        true,
        false
      );
    }
  }
  return (
    <div>
      {field.type === "file" &&
      [
        "documentoDam",
        "adjuntarDim",
        "parteDeRecepcion",
        "adjuntarDex",
        "dimRegularizada",
      ].indexOf(field.name) > -1 ? (
        <BoxFlex justifyContent="space-between">
          <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
            Editar dato
          </TextH2>
          {loadBtn ? (
            <Loading />
          ) : (
            <GlobalButton onClick={() => handleRead(field.name)} width="auto">
              Volver a extraer datos
            </GlobalButton>
          )}
        </BoxFlex>
      ) : (
        <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
          Editar dato
        </TextH2>
      )}
      <FormDefault
        load={load}
        title=""
        textBtn="Editar"
        InputsJson={handlePreRender(field)}
        data={{ value: field.initial }}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};

export default connect(mapStateToProps, { openSnackbar, changeModal })(
  EditDataset
);
